export default {
  state: () => ({
    invoices: [],
    balance: {},
    accountId: undefined,
    creditorId: undefined
  }),
  mutations: {
    setBalance (state, balance) {
      state.balance = balance;
    },
    setInvoices (state, invoices) {
      state.invoices = invoices;
    },
    setAccountId (state, accountId) {
      state.accountId = accountId;
    },
    setCreditorId (state, creditorId) {
      state.creditorId = creditorId;
    }
  },
  actions: {
    setBasket ({ commit }, basket) {
      commit('setInvoices', basket.invoices);
      commit('setBalance', basket.balance);
      commit('setAccountId', basket.accountId);
      commit('setCreditorId', basket.creditorId);
    },
    clearBasket ({ commit }) {
      commit('setInvoices', []);
      commit('setBalance', {});
      commit('setAccountId', undefined);
      commit('setCreditorId', undefined);
    }
  }
};
