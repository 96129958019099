<template>
  <v-card>
    <v-footer
      padless
      :class="{ 'mb-100': displayMarginBottom }"
    >
      <v-card
        flat
        tile
        width="100%"
        class="primary text-center"
      >
        <v-card-text class="d-inline-flex align-center justify-end pa-0" style="height: 70px">
          <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.smAndUp" @click="drawer = !drawer" class="mr-auto white ma-2"></v-app-bar-nav-icon>
          <v-navigation-drawer
            v-model="drawer"
            width="auto"
            height="auto"
            class="primary"
            :temporary="!$vuetify.breakpoint.smAndUp"
            :permanent="$vuetify.breakpoint.smAndUp"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'PersonalData' }"
                  v-if="!isAdmin()"
                >
                  <v-icon
                    color="white"
                    size="24px">
                      mdi-card-account-details
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TOOLTIP_PERSONAL_DATA') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'TermsAndConditionsHome' }"
                  v-if="!isAdmin()"
                >
                  <v-icon
                    color="white"
                    size="24px">
                      mdi-file-document-edit
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TOOLTIP_TERMS_AND_CONDITIONS') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'LegalTermsHome' }"
                  v-if="!isAdmin()"
                >
                  <v-icon
                    color="white"
                    size="24px">
                      mdi-bank
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TOOLTIP_LEGAL_TERMS') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'FaqHome' }"
                  v-if="!isAdmin()"
                >
                  <v-icon
                    color="white"
                    size="24px">
                      mdi-help-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TOOLTIP_FAQ') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="contact.dialog = true"
                  v-if="!isAdmin()"
                >
                  <v-icon
                    color="white"
                    size="24px">
                      mdi-email
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TOOLTIP_CONTACT') }}</span>
            </v-tooltip>
          </v-navigation-drawer>
          <v-select
              class="language-selector rounded-lg col-2"
              background-color="white"
              :items="languages"
              v-model="currentLanguage"
              item-value="locale"
              hide-details="auto"
              return-object
          >
            <template v-slot:item="{ on, attrs, item }">
              <v-img
                  max-height="25"
                  max-width="25"
                  v-bind="attrs"
                  v-on="on"
                  :src="require(`@/assets/flags/${item.flag}`)"
              ></v-img>
              <span class="ml-2 small">{{ item.name }}</span>
            </template>
            <template v-slot:selection="{ on, attrs, item }">
              <v-img
                  class="ml-2"
                  max-height="25"
                  max-width="25"
                  v-bind="attrs"
                  v-on="on"
                  :src="require(`@/assets/flags/${item.flag}`)"
              ></v-img>
              <span class="ml-2 small font-weight-bold">{{ item.vuetifyLocale | uppercase }}</span>
            </template>
          </v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="grey lighten-4">
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <span v-if="$vuetify.breakpoint.smAndUp" class="caption text-uppercase">&copy; Bill Pay &amp; Match {{ version }}</span>

          <v-spacer></v-spacer>

          <v-img
            contain
            src="https://worldline.com/content/dam/worldline/structural/logos/worldline-logo.svg"
            max-width="100"
          ></v-img>
        </v-card-actions>
      </v-card>
    </v-footer>

    <v-dialog
      v-model="contact.dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t('DIALOG_CONTACT_TITLE') }}
        </v-card-title>

        <v-card-text class="mt-4">

          <i18n :path="dialogContactMessage" tag="span">
            <template #clientITNum v-if="!this.shouldDisplayDefaultDialogContactMessage">
              <strong>{{ clientITNum }}</strong>
            </template>
            <template #clientServiceMail>
               <a :href="'mailto:' + clientServiceMail" target="_blank">{{ clientServiceMail }}</a>
            </template>
          </i18n>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="contact.dialog = false"
          >{{ $t('ACTION_CLOSE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import eventService from '@/services/eventService';
import localeService from '@/services/localeService';
import eventBus from '@/events';
import userMixin from '@/mixins/userMixin';
import publicDataMixin from '@/mixins/publicDataMixin';
import localeMixin from '@/mixins/localeMixin';
import { mapState } from 'vuex';

export default {
  mixins: [userMixin, publicDataMixin, localeMixin],
  computed: {
    ...mapState(['locale']),
    shouldDisplayDefaultDialogContactMessage () {
      return this.clientsWithDefaultData.includes(this.clientName);
    },
    dialogContactMessage () {
      return this.shouldDisplayDefaultDialogContactMessage ? 'DIALOG_CONTACT_MESSAGE_DEFAULT' : `DIALOG_CONTACT_MESSAGE_${this.clientName.toUpperCase()}`;
    }
  },
  data: () => ({
    contact: {
      dialog: false
    },
    currentLanguage: {},
    languages: [],
    version: null,
    displayMarginBottom: false,
    drawer: false
  }),
  async beforeMount () {
    this.languages = Object.values(localeService.getAllConfigurations());
    if (this.locale) {
      this.updateCurrentLanguageFromLocale(this.locale);
    }

    try {
      const { data } = await eventService.getServerInfo();
      const versionRegex = /\d\.\d\.\d/;
      this.version = data.version.match(versionRegex)[0];
    } catch (err) {
      // do nothing
    }
    eventBus.$on('footer:extend', () => {
      this.displayMarginBottom = true;
    });
    eventBus.$on('footer:shrink', () => {
      this.displayMarginBottom = false;
    });
  },
  methods: {
    updateCurrentLanguageFromLocale () {
      this.currentLanguage = this.languages.filter(language => language.locale === this.locale)[0];
    }
  },
  watch: {
    locale (locale) {
      this.updateCurrentLanguageFromLocale(locale);
    },
    currentLanguage (language) {
      if (language.locale !== this.locale) {
        this.updateApplicationLanguage(language);
      }
    }
  }
};
</script>

<style scoped>
.language-selector {
  min-width: 120px;
  max-width: 120px;
}

.mb-100 {
  margin-bottom: 100px;
}
</style>