class EnvironmentService {
  constructor () {
    this.keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL;
    this.realm = process.env.VUE_APP_KEYCLOAK_REALM;
    this.backendUrl = process.env.VUE_APP_BACKEND_URL;
    this.logoUrl = `${process.env.VUE_APP_LOGO_BASE_URL}/${this.realm}/logo.png`.concat(`?timestamp=${Date.now()}`);
    this.faviconUrl = `${process.env.VUE_APP_LOGO_BASE_URL}/${this.realm}/favicon.png`.concat(`?timestamp=${Date.now()}`);
    this.isProductionEnv = process.env.NODE_ENV === 'production';

    if (this.isProductionEnv) {
      const { protocol, host, origin } = new URL(window.location.href);
      this.keycloakUrl = `${protocol}//${host.replace(/^[^.]+\./g, '')}/auth`;
      this.realm = host.match(/^([^.]+)/g)[0];
      this.backendUrl = origin + '/';
      this.logoUrl = `${origin}/static/${this.realm}/logo.png`.concat(`?timestamp=${Date.now()}`);
      this.faviconUrl = `${origin}/static/${this.realm}/favicon.png`.concat(`?timestamp=${Date.now()}`);
    }
  }

  getCurrentLogoFromRealm (realm) {
    if (this.isProductionEnv) {
      const { origin } = new URL(window.location.href);
      return `${origin}/static/${realm}/logo.png`;
    } else {
      return `${process.env.VUE_APP_LOGO_BASE_URL}/${realm}/logo.png`;
    }
  }

  getCurrentFaviconFromRealm (realm) {
    if (this.isProductionEnv) {
      const { origin } = new URL(window.location.href);
      return `${origin}/static/${realm}/favicon.png`;
    } else {
      return `${process.env.VUE_APP_LOGO_BASE_URL}/${realm}/favicon.png`;
    }
  }
}

export default new EnvironmentService();
