import keycloakService from '@/services/keycloakService';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      user: keycloakService.user,
      authenticated: keycloakService.authenticated,
      creditorRoles: [
        {
          text: this.$t('LABEL_CREDITOR_ADMIN'),
          value: 'CREDITOR_ADMIN'
        },
        {
          text: this.$t('LABEL_CREDITOR_ACCOUNT_MANAGER'),
          value: 'CREDITOR_ACCOUNT_MANAGER'
        },
        {
          text: this.$t('LABEL_CREDITOR_TECHNICAL'),
          value: 'CREDITOR_TECHNICAL'
        },
        {
          text: this.$t('LABEL_CREDITOR_USER'),
          value: 'CREDITOR_USER',
          shouldBeHidden: true // TODO: This role will be removed when users with 'user' role have been migrated
        }
      ],
      creditorSubRoles: [
        {
          text: this.$t('LABEL_CREDITOR_DEBTOR_FOLLOW_UP'),
          value: 'CREDITOR_DEBTOR_FOLLOW_UP'
        },
        {
          text: this.$t('LABEL_CREDITOR_INVOICE_MANAGEMENT'),
          value: 'CREDITOR_INVOICE_MANAGEMENT'
        },
        {
          text: this.$t('LABEL_CREDITOR_DATA_TRANSFER'),
          value: 'CREDITOR_DATA_TRANSFER'
        },
        {
          text: this.$t('LABEL_CREDITOR_CONSENT_MANAGEMENT'),
          value: 'CREDITOR_CONSENT_MANAGEMENT'
        },
        {
          text: this.$t('LABEL_CREDITOR_DEBT_MANAGEMENT'),
          value: 'CREDITOR_DEBT_MANAGEMENT'
        }
      ],
      titleItems: [
        {
          text: this.$t('MISS'),
          value: 'MISS'
        },
        {
          text: this.$t('MS'),
          value: 'MS'
        },
        {
          text: this.$t('MR'),
          value: 'MR'
        }
      ]
    };
  },
  methods: {
    isCreditor () {
      return keycloakService.isCreditor();
    },
    isCreditorAdmin () {
      return keycloakService.isCreditorAdmin();
    },
    isCreditorAccountManager () {
      return keycloakService.isCreditorAccountManager();
    },
    isConsentManager () {
      return this.subRolesOnSelectedCreditorAccount.includes('CREDITOR_CONSENT_MANAGEMENT');
    },
    isDebtor () {
      return keycloakService.isDebtor();
    },
    isDebtorExtSSOAutoLink () {
      return keycloakService.isDebtorExtSSOAutoLink();
    },
    isAdmin () {
      return keycloakService.isBackofficeAdmin();
    },
    computeDebtorUserRole (roleValue) {
      const debtorUserRole = roleValue;
      return this.debtorRoles.find(role => role.value === debtorUserRole).text;
    },
    computeCreditorUserRole (roleValue) {
      if (roleValue === undefined) return this.$t('MISSING_ROLE');
      return this.creditorRoles.find(role => role.value === roleValue).text;
    },
    computeTitle (titleValue) {
      if (titleValue === undefined) return '';
      return this.titleItems.find(title => title.value === titleValue).text;
    },
    computeCreditorSubRole (subRoleValue) {
      return this.creditorSubRoles.find(subRole => subRole.value === subRoleValue).text;
    },
    specificServicesContainsValue (value) {
      return this.selectedCreditorAccount &&
          Array.isArray(this.selectedCreditorAccount.creditorSettings.SPECIFIC_SERVICES.value) &&
          this.selectedCreditorAccount.creditorSettings.SPECIFIC_SERVICES.value.includes(value);
    }
  },
  computed: {
    ...mapState(['creditor', 'subRolesOnSelectedCreditorAccount', 'selectedCreditorAccount']),
    isValidationDisabled () {
      if (this.isCreditor()) {
        return !(this.selectedCreditorAccount && this.selectedCreditorAccount.creditorSettings.VALIDATION_ENABLED.value);
      } else {
        return !(this.creditor && this.creditor.creditorSettings.VALIDATION_ENABLED.value);
      }
    },
    userInitials () {
      const { firstName, lastName } = this.user;
      return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
    },
    debtorRoles () {
      return [
        {
          text: this.$t('LABEL_DEBTOR_ADMIN'),
          value: 'DEBTOR_ADMIN'
        },
        {
          text: this.$t('LABEL_DEBTOR_APPROVER'),
          value: 'DEBTOR_APPROVER',
          shouldBeHidden: this.isValidationDisabled
        },
        {
          text: this.$t('LABEL_DEBTOR_PAYER'),
          value: 'DEBTOR_PAYER'
        }
      ];
    },
    availableDebtorRoles () {
      return this.debtorRoles.filter(item => !item.shouldBeHidden);
    },
    debtorUserRole () {
      return this.$store.state.selectedDebtorAccount.role;
    },
    isDebtorAdmin () {
      return this.debtorUserRole === 'DEBTOR_ADMIN';
    },
    isDebtorApprover () {
      return this.debtorUserRole === 'DEBTOR_APPROVER';
    },
    isDebtorPayer () {
      return this.debtorUserRole === 'DEBTOR_PAYER';
    },
    isOnMobileSupport () {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
    }
  }
};