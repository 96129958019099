import Service from './Service';
import isEmpty from 'lodash/isEmpty';

class LocaleService extends Service {
  constructor () {
    super();
    this.locales = new Set();
    this.messages = {};
    this.configurations = {};
    this.currentLocale = null;
  }

  register ({ locale, name, flag, momentLocale, vuetifyLocale, defaultCurrency, messages }) {
    this.locales.add(locale);
    this.messages[locale] = messages;
    this.configurations[locale] = {
      locale,
      name,
      flag,
      momentLocale,
      vuetifyLocale,
      defaultCurrency
    };
  }

  get currentCurrency () {
    if (this.currentLocale) {
      return this.configurations[this.currentLocale].defaultCurrency;
    }
    return null;
  }

  get currentConfiguration () {
    return this.getConfiguration(this.currentLocale);
  }

  getConfiguration (locale) {
    return this.configurations[locale];
  }

  getMessages (locale) {
    return this.messages[locale];
  }

  getAllConfigurations () {
    return this.configurations;
  }

  getAllMessages () {
    return this.messages;
  }

  initLocale (storedLocale) {
    const locale = null;
    // Step 1 : check app state to search currentLanguage (JIRA 2061)
    if (storedLocale) {
      return storedLocale;
    } else {
      // Step 2 : if not, check keycloak user token to search preferredLanguage (JIRA 2061)

      // Step 3 : if not, check user browser favorite language (JIRA 2062)

    }
    // Step 4 : if not, check into context to search creditor preferred language (JIRA 2063). This control is done into specific Components, such as OneOffCheckout
    return locale;
  }

  getLanguageFromLocale (locale) {
    const languages = Object.values(this.getAllConfigurations());
    return languages.filter(language => language.locale === locale)[0];
  }

  getLanguageFromCreditorPreferredLanguage (creditorLanguage) {
    const languages = Object.values(this.getAllConfigurations());
    return languages.filter(language => language.vuetifyLocale === creditorLanguage.toLowerCase())[0];
  }

  getMessagesByRealm () {
    return this.instanceNoAuth.get('userapi/public/v2/static-files/internationalization');
  }

  overloadI18nMessages (realmMessages) {
    if (!isEmpty(realmMessages)) {
      this.locales.forEach(l => {
        this.messages[l] = Object.assign(this.messages[l], realmMessages[l]);
      });
    }
  }
}

export default new LocaleService();
