<template>
  <v-snackbar
    v-model="display"
    :color="color"
  >

    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        @click="display = false"
        icon
        v-bind="attrs"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

  </v-snackbar>
</template>

<script>
import eventBus from '@/events';

export default {
  data: () => ({
    display: false,
    color: '',
    message: ''
  }),
  beforeMount () {
    eventBus.$on('snackbar:show', options => {
      this.message = options.message;
      this.color = options.color || 'error';
      this.display = true;
    });
    eventBus.$on('snackbar:hide', () => {
      this.display = false;
    });
  }
};
</script>
