import { mapState } from 'vuex';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  computed: {
    ...mapState(['creditor', 'selectedCreditorAccount', 'selectedDebtorAccount']),
    menuItems () {
      let menuItems = [];
      if (this.isCreditor()) {
        menuItems = this.creditorMenuItems;
        menuItems = menuItems.filter(menu => !menu.shouldBeHidden);
        menuItems.map(item => {
          if (item.subMenu !== undefined) {
            item.subMenu = item.subMenu.filter(subMenu => !subMenu.shouldBeHidden);
          }
          return item;
        });
        menuItems = menuItems.filter(item => !item.hide);
      } else if (this.isDebtor()) {
        menuItems = this.debtorMenuItems;
        menuItems = menuItems.filter(item => !item.shouldBeHidden);
      } else if (this.isAdmin()) {
        menuItems = this.adminMenuItems;
      }
      return menuItems;
    },
    adminMenuItems () {
      return [
        {
          linkTo: 'BackofficeReporting',
          exact: true,
          menuLabel: this.$t('CUSTOMER_REPORTING'),
          subMenu: [
            {
              linkTo: 'BackofficeCustomerBilling',
              exact: true,
              menuLabel: this.$t('BO_CUSTOMER_BILLING')
            },
            {
              linkTo: 'BackofficeProvidersBilling',
              exact: true,
              menuLabel: this.$t('BO_PROVIDERS_BILLING')
            },
            {
              linkTo: 'BackofficeKpiSales',
              exact: true,
              menuLabel: this.$t('BO_KPI_SALES')
            }
          ]
        },
        {
          linkTo: 'BackofficeManagement',
          exact: true,
          menuLabel: this.$t('CUSTOMER_MANAGEMENT'),
          subMenu: [
            {
              linkTo: 'BackofficeCreditorManagement',
              exact: true,
              menuLabel: this.$t('BO_CREDITOR_MANAGEMENT')
            },
            {
              linkTo: 'BackofficeFileExchanger',
              exact: true,
              menuLabel: this.$t('BO_FILE_EXCHANGER')
            }
          ]
        }
      ];
    },
    creditorMenuItems () {
      return [
        {
          linkTo: 'CreditorHome',
          exact: true,
          menuLabel: this.$t('HOME')
        },
        {
          exact: true,
          menuLabel: this.$t('FOLLOW_UP'),
          linkTo: 'CreditorFollowUp',
          subMenu: [
            {
              linkTo: 'CreditorPaidInvoices',
              exact: true,
              menuLabel: this.$t('PAID')
            },
            {
              linkTo: 'CreditorToPayInvoices',
              exact: true,
              menuLabel: this.$t('TO_PAY')
            },
            {
              linkTo: 'CreditorRejectedInvoices',
              exact: true,
              menuLabel: this.$t('REJECTED'),
              shouldBeHidden: this.isValidationDisabled
            },
            {
              linkTo: 'CreditorPaymentInvoices',
              exact: true,
              menuLabel: this.$t('PAYMENTS'),
              // TODO: this menu must be hidden until we implement the Creditor Payment page
              shouldBeHidden: true
            }
          ]
        },
        {
          linkTo: 'CreditorDataTransfer',
          exact: true,
          menuLabel: this.$t('DATA_TRANSFER'),
          hide: true
        },
        {
          linkTo: 'CreditorConfiguration',
          exact: true,
          menuLabel: this.$t('CREDITOR_CONFIGURATION'),
          hide: !this.isCreditorAdmin() && !this.isConsentManager(), // TODO: also available for account-managers, consent-managers when implemented
          subMenu: [
            {
              linkTo: 'CreditorSettingsAccounts',
              exact: true,
              menuLabel: this.$t('SETTINGS_ACCOUNTS')
            },
            {
              linkTo: 'CreditorSettingsUsers',
              exact: true,
              menuLabel: this.$t('SETTINGS_USERS'),
              shouldBeHidden: !this.isCreditorAdmin()
            }
          ]
        },
        {
          exact: true,
          menuLabel: this.$t('DEBT_MANAGEMENT'),
          linkTo: 'DebtManagement',
          shouldBeHidden: !this.specificServicesContainsValue('DebtManagement'),
          subMenu: [
            {
              linkTo: 'CreditorCreateInvoices',
              exact: true,
              menuLabel: this.$t('MENU_CREATE')
            },
            {
              linkTo: 'CreditorCreatedInvoices',
              exact: true,
              menuLabel: this.$t('STATUS_CREATED')
            }
          ]
        }
      ];
    },
    debtorMenuItems () {
      return [
        {
          linkTo: 'DebtorHome',
          exact: true,
          menuLabel: this.$t('HOME')
        },
        {
          linkTo: 'DebtorToValidateInvoices',
          exact: true,
          menuLabel: this.$t('TO_VALIDATE'),
          shouldBeHidden: this.isValidationDisabled,
          badgeProperty: 'toValidateInvoicesCount'
        },
        {
          linkTo: 'DebtorToPayInvoices',
          exact: true,
          menuLabel: this.$t('TO_PAY'),
          badgeProperty: 'toPayInvoicesCount'
        },
        {
          linkTo: 'DebtorPaidInvoices',
          exact: true,
          menuLabel: this.$t('PAID')
        },
        {
          linkTo: 'DebtorPayments',
          exact: true,
          menuLabel: this.$t('PAYMENTS')
        }
      ];
    }
  }
};
