<template>
  <v-app>
    <AppHeader v-if="!contextLoading"/>
    <v-main :key="startupLocale">
      <router-view v-if="!contextLoading"/>
      <div v-else class="app-loading text-center">
        <v-progress-circular
          :size="70"
          indeterminate
          color="primary">
        </v-progress-circular>
        <div class="mt-7 text-uppercase">{{ $t("LOADING") }}</div>
      </div>
    </v-main>
    <AlertSnackbar/>
    <AppFooter/>
  </v-app>
</template>

<script>
import keycloakService from '@/services/keycloakService';
import contextService from '@/services/contextService';
import environmentService from '@/services/environmentService';
import AppHeader from '@/components/common/AppHeader.vue';
import AppFooter from '@/components/common/AppFooter.vue';
import AlertSnackbar from '@/components/common/AlertSnackbar.vue';
import userMixin from '@/mixins/userMixin';
import userService from '@/services/userService';
import creditorUtils from '@/utils/creditorUtils';
import { mapState } from 'vuex';
import localeService from './services/localeService';
import localeMixin from './mixins/localeMixin';

export default {
  data () {
    return {
      contextLoading: true
    };
  },
  mixins: [userMixin, localeMixin],
  components: {
    AppHeader,
    AppFooter,
    AlertSnackbar
  },
  computed: {
    ...mapState([
      'primaryColor',
      'secondaryColor',
      'selectedCreditorAccount'
    ]),
    startupLocale () {
      return this.$store.state.locale || this.$root.$i18n.locale;
    }
  },
  async beforeCreate () {
    const initialLocale = await localeService.initLocale(this.$store.state.locale);
    await this.initApplicationLocale(initialLocale);
  },
  async beforeMount () {
    // Force to load the favicon from the server by adding a unique timestamp
    document.getElementById('favicon').href = environmentService.faviconUrl;
    if (this.isAdmin()) {
      this.contextLoading = false;
      await this.initApplicationLocale('fr-FR');
      return;
    } else if (!keycloakService.authenticated) {
      this.contextLoading = false;
      return;
    }
    try {
      let mainCreditor = null;
      if (this.isCreditor()) {
        const { data } = await userService.getCreditorAccountsOfUser();
        const creditorAccounts = creditorUtils.orderList(data);
        mainCreditor = creditorAccounts[0];
        await this.$store.dispatch('setCreditorContext', { creditorAccounts: creditorAccounts });
        await this.$store.dispatch('setSelectedDebtorAccountsForCreditor', []);
        await this.$store.dispatch('setDebtorAccounts', []);
        if (this.isCreditorAccountManager()) {
          const { data } = await userService.getUserSubRoles(this.user.id, this.selectedCreditorAccount.id);
          const subRoles = data.map(roleDto => roleDto.role);
          await this.$store.dispatch('setSubRolesOnSelectedCreditorAccount', { subRolesOnSelectedCreditorAccount: subRoles });
        }
      } else {
        // Debtor user
        if (this.isDebtorExtSSOAutoLink()) {
          await userService.autoLinkDebtorAccountToDebtorUser();
        }
        const { data } = await contextService.getContext();
        mainCreditor = data.creditor;
        await this.$store.dispatch('setDebtorContext', { creditor: data.creditor, debtors: data.debtors });
      }
      if (!this.$store.state.locale) {
        await this.initApplicationLocaleFromMainCreditor(mainCreditor);
      }
    } catch (err) {
      this.handleError(err);
    } finally {
      this.$vuetify.theme.themes.light.primary = this.primaryColor;
      this.$vuetify.theme.themes.light.secondary = this.secondaryColor;
      this.contextLoading = false;
    }
  },
  methods: {
    async initApplicationLocale (initialLocale) {
      if (initialLocale) {
        const initialLanguage = await localeService.getLanguageFromLocale(initialLocale);

        if (initialLanguage) {
          await this.updateApplicationLanguage(initialLanguage);
        }
      }

      const { data } = await localeService.getMessagesByRealm();
      localeService.overloadI18nMessages(data);
    },
    async initApplicationLocaleFromMainCreditor (mainCreditor) {
      if (mainCreditor && !this.$store.state.locale) { // If locale has not been initiated from store or user token, then setup locale from creditorSetting, if exists
        const mainCreditorLanguage = mainCreditor.creditorSettings.LANGUAGE.value;
        const initialLanguage = await localeService.getLanguageFromCreditorPreferredLanguage(mainCreditorLanguage);

        if (initialLanguage) {
          await this.updateApplicationLanguage(initialLanguage);
        }
      }
    }
  }
};
</script>

<style>
@import './assets/styles/global.css';
.app-loading {
  margin-top: 10vh;
}
</style>