import Vue from 'vue';
import VueI18n from 'vue-i18n';

import localeService from '@/services/localeService';

localeService.register({
  locale: 'fr-FR',
  name: 'Français',
  flag: 'fr.svg',
  momentLocale: 'fr',
  vuetifyLocale: 'fr',
  defaultCurrency: 'EUR',
  messages: Object.assign({}, require('../locales/fr-FR.json'), require('../locales/backoffice/fr-FR.json'))
});

localeService.register({
  locale: 'en-GB',
  name: 'English (British)',
  flag: 'gb.svg',
  momentLocale: 'en-gb',
  vuetifyLocale: 'en',
  defaultCurrency: 'GBP',
  messages: Object.assign({}, require('../locales/en-GB.json'), require('../locales/backoffice/en-GB.json'))
});

localeService.register({
  locale: 'de-DE',
  name: 'Deutsch',
  flag: 'de.svg',
  momentLocale: 'de',
  vuetifyLocale: 'de',
  defaultCurrency: 'EUR',
  messages: Object.assign({}, require('../locales/de-DE.json'), require('../locales/backoffice/de-DE.json'))
});

localeService.register({
  locale: 'nl-NL',
  name: 'Nederlands',
  flag: 'nl.svg',
  momentLocale: 'nl',
  vuetifyLocale: 'nl',
  defaultCurrency: 'EUR',
  messages: Object.assign({}, require('../locales/nl-NL.json'), require('../locales/backoffice/nl-NL.json'))
});

localeService.register({
  locale: 'it-IT',
  name: 'Italiano',
  flag: 'it.svg',
  momentLocale: 'it',
  vuetifyLocale: 'it',
  defaultCurrency: 'EUR',
  messages: Object.assign({}, require('../locales/it-IT.json'), require('../locales/backoffice/it-IT.json'))
});

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'fr-FR',
  fallbackLocale: 'fr-FR',
  messages: localeService.getAllMessages(),
  silentFallbackWarn: true
});
