import Service from './Service';
import environmentService from './environmentService';

class ContextService extends Service {
  getContext () {
    const realm = environmentService.realm;
    return this.instance.get(`userapi/v2/realm/${realm}/context`);
  }

  /**
   * Returns the realms list
   *
   * @returns {Object} Realms list
   */
  getRealmsList () {
    return this.instance.get('userapi/v2/realms');
  }
}

export default new ContextService();