import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/logger';
import commonFilters from './utils/commonFilters';
import globalMixin from './mixins/globalMixin';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

import keycloakService from './services/keycloakService';

Vue.config.productionTip = false;

keycloakService.init().then(() => {
  Vue.mixin(commonFilters);
  Vue.mixin(globalMixin);

  new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
});