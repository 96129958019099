import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#27509B',
        secondary: '#38465B'
      }
    }
  },
  lang: {
    locales: { fr, en, de }
  }
});
