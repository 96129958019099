import localeService from '@/services/localeService';
import moment from 'moment-timezone';

export default {
  methods: {
    updateApplicationLanguage (newLanguage) {
      this.$root.$i18n.locale = newLanguage.locale;
      moment.locale(newLanguage.momentLocale);
      this.$vuetify.lang.current = newLanguage.vuetifyLocale;
      localeService.currentLocale = newLanguage.locale;
      this.$store.dispatch('setLocale', newLanguage.locale);
    },
    async updateApplicationLanguageFromCreditorPreferredLanguage (creditorPreferredLanguage) {
      const creditorLanguage = await localeService.getLanguageFromCreditorPreferredLanguage(creditorPreferredLanguage);
      this.updateApplicationLanguage(creditorLanguage);
    }
  }
};