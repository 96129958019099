export const ErrorMessagesCorrelationMap = new Map([
  ['Invoice status not accepted', 'STATUS_NOT_ACCEPTED'],
  ['Payment not valid', 'BUCKET_NOT_VALID'],
  ['Two step without Credit_card', 'TWO_STEP_CREDIT_CARD'],
  ['Payment mean not supported', 'PAYMENT_MEAN_NOT_SUPPORTED'],
  ['Payment deadline has passed', 'PAYMENT_LINK_EXPIRED'],
  ['Invoice name already exists', 'INVOICE_NAME_ALREADY_EXISTS'],
  ['Business Key has already been used on this debtor', 'BUSINESS_KEY_ALREADY_USED'],
  ['Business Key does not belong to the debtorAccount sent into the request', 'ERROR_INCORRECT_DATA_CONTACT_CREDITOR'],
  ['Business Key must be activated to process the association', 'BUSINESS_KEY_NOT_ACTIVATED'],
  ['Business Key has expired', 'BUSINESS_KEY_NOT_ACTIVATED']
]);