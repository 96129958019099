import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import basketModule from './basketModule';

Vue.use(Vuex);

const DEFAULT_STATE = {
  creditor: null,
  creditorAccounts: [],
  debtorAccounts: [],
  selectedDebtorAccount: null,
  selectedDebtorAccountsForCreditor: null,
  selectedCreditorAccount: null,
  subRolesOnSelectedCreditorAccount: [],
  oneOffDebtor: null,
  primaryColor: '#27509B',
  secondaryColor: '#38465B',
  locale: null
};

const DEFAULT_MODULES = {
  basket: {}
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: { ...DEFAULT_STATE },
  mutations: {
    setCreditor (state, creditor) {
      state.creditor = creditor;
    },
    setCreditorAccounts (state, creditorAccounts) {
      state.creditorAccounts = creditorAccounts;
    },
    setSelectedCreditorAccount (state, creditorAccount) {
      state.selectedCreditorAccount = creditorAccount;
    },
    setDebtorAccounts (state, debtorAccounts) {
      state.debtorAccounts = debtorAccounts;
    },
    setSelectedDebtorAccount (state, debtorAccount) {
      state.selectedDebtorAccount = debtorAccount;
    },
    setSelectedDebtorAccountsForCreditor (state, debtorAccounts) {
      state.selectedDebtorAccountsForCreditor = debtorAccounts;
    },
    setSubRolesOnSelectedCreditorAccount (state, subRolesOnSelectedCreditorAccounts) {
      state.subRolesOnSelectedCreditorAccount = subRolesOnSelectedCreditorAccounts;
    },
    setOneOffDebtor (state, oneOffDebtor) {
      state.oneOffDebtor = oneOffDebtor;
    },
    setPrimaryColor (state, primaryColor) {
      state.primaryColor = primaryColor;
    },
    setSecondaryColor (state, secondaryColor) {
      state.secondaryColor = secondaryColor;
    },
    setLocale (state, locale) {
      state.locale = locale;
    },
    resetState () {
      this.replaceState({ ...DEFAULT_STATE, ...DEFAULT_MODULES });
    }
  },
  actions: {
    setCreditorContext ({ commit }, { creditorAccounts, debtors }) {
      commit('setCreditorAccounts', creditorAccounts);
      if (!this.state.selectedCreditorAccount || this.state.selectedCreditorAccount === null) {
        commit('setSelectedCreditorAccount', creditorAccounts ? creditorAccounts[0] : undefined);
      }
      commit('setDebtorAccounts', debtors);
      if (!this.state.selectedDebtorAccount || this.state.selectedDebtorAccount === null) {
        commit('setSelectedDebtorAccount', debtors ? debtors[0] : undefined);
      }
      if (!this.state.selectedDebtorAccountsForCreditor || this.state.selectedDebtorAccountsForCreditor === null) {
        commit('setSelectedDebtorAccountsForCreditor', debtors || undefined);
      }
      if (this.state.selectedCreditorAccount.creditorSettings.PRIMARY_COLOR) {
        commit('setPrimaryColor', this.state.selectedCreditorAccount.creditorSettings.PRIMARY_COLOR.value);
      }
      if (this.state.selectedCreditorAccount.creditorSettings.SECONDARY_COLOR) {
        commit('setSecondaryColor', this.state.selectedCreditorAccount.creditorSettings.SECONDARY_COLOR.value);
      }
    },
    setDebtorContext ({ commit }, { creditor, debtors }) {
      commit('setCreditor', creditor);
      commit('setDebtorAccounts', debtors);
      if (!this.state.selectedDebtorAccount || this.state.selectedDebtorAccount === null) {
        commit('setSelectedDebtorAccount', debtors ? debtors[0] : undefined);
      }
      if (creditor.creditorSettings.PRIMARY_COLOR) {
        commit('setPrimaryColor', creditor.creditorSettings.PRIMARY_COLOR.value);
      }
      if (creditor.creditorSettings.SECONDARY_COLOR) {
        commit('setSecondaryColor', creditor.creditorSettings.SECONDARY_COLOR.value);
      }
    },
    setCreditorAccounts ({ commit }, { creditorAccounts }) {
      commit('setCreditorAccounts', creditorAccounts);
    },
    setSelectedCreditorAccount ({ commit }, { creditorAccount }) {
      commit('setSelectedCreditorAccount', creditorAccount);
    },
    setSubRolesOnSelectedCreditorAccount ({ commit }, { subRolesOnSelectedCreditorAccount }) {
      commit('setSubRolesOnSelectedCreditorAccount', subRolesOnSelectedCreditorAccount);
    },
    setSelectedDebtorAccount ({ commit }, { debtorAccount }) {
      commit('setSelectedDebtorAccount', debtorAccount);
    },
    setSelectedDebtorAccountsForCreditor ({ commit }, { debtorAccounts }) {
      commit('setSelectedDebtorAccountsForCreditor', debtorAccounts);
    },
    setDebtorAccounts ({ commit }, { debtorAccounts }) {
      commit('setDebtorAccounts', debtorAccounts);
    },
    setOneOffDebtor ({ commit }, oneOffDebtor) {
      commit('setOneOffDebtor', oneOffDebtor);
    },
    setPrimaryColor ({ commit }, primaryColor) {
      commit('setPrimaryColor', primaryColor);
    },
    setSecondaryColor ({ commit }, secondaryColor) {
      commit('setSecondaryColor', secondaryColor);
    },
    setLocale ({ commit }, locale) {
      commit('setLocale', locale);
    },
    resetState ({ commit }) {
      commit('resetState');
    }
  },
  modules: {
    basket: basketModule
  }
});
