import keycloak from 'keycloak-js';
import environmentService from '@/services/environmentService';
import Vue from 'vue';
import store from '@/store';

/**
 * Service that manages Keycloak interaction inside BP&M.
 * Warning: This service MAY NOT depend on any other service, otherwise there might be a cyclic-dependency
 */
class KeycloakService {
  /**
   * The constructor will initialize the Keycloak object given the correct information
   */
  constructor () {
    this.keycloak = keycloak({
      url: environmentService.keycloakUrl,
      realm: environmentService.realm,
      clientId: 'bpm-portal'
    });
    this.interval = null;
  }

  /**
   * Boolean that indicates whether the user is authenticated or not.
   * It should be used as a property, not a method.
   *
   * Example:
   * if (keycloakService.authenticated) {
   *   log(keycloakService.user.firstName);
   * }
   */
  get authenticated () {
    return this.keycloak.authenticated;
  }

  /**
   * Object containing information in regards to the user currently logged-in. If no user is logged in, this will
   * return null.
   * It should be used as a property, not a method.
   */
  get user () {
    if (!this.keycloak.authenticated || !this.keycloak.idTokenParsed) {
      return null;
    }

    const { idTokenParsed } = this.keycloak;

    return {
      id: idTokenParsed.sub,
      firstName: idTokenParsed.given_name,
      lastName: idTokenParsed.family_name,
      fullName: idTokenParsed.name,
      locale: idTokenParsed.locale,
      email: idTokenParsed.email,
      gender: idTokenParsed.gender,
      roles: idTokenParsed.realm_access,
      groups: idTokenParsed.groups,
      phoneNumber: idTokenParsed.phone_number,
      channels: idTokenParsed.channels?.map(channel => channel.toUpperCase()) || []
    };
  }

  /**
   * Retrieve the current token used to identify the user. If no user is authentified, you will get a null
   */
  get token () {
    return this.keycloak.token;
  }

  debug () {
    Vue.$log.debug('debugging keycloak object', this.keycloak);
  }

  /**
   * Initializer to the connection between BP&M and Keycloak, should only be called once upon startup.
   * If the user is already authenticated, this will set up an interval to refresh the user's token
   */
  async init () {
    try {
      const auth = await this.keycloak.init({
        onLoad: 'check-sso',
        checkLoginIframe: false
      });
      if (auth) {
        this.interval = setInterval(() => {
          this.refreshToken();
        }, 6000);
      }
    } catch (err) {
      Vue.$log.fatal('cannot contact Keycloak server', err);
    }
  }

  /**
   * Token refresh method, you don't need to explicitly call this method, as it is automatically called
   * during the init, if the user is logged in.
   */
  refreshToken () {
    if (!this.authenticated) {
      return;
    }
    return this.keycloak.updateToken(70).then(() => {}).catch(() => {});
  }

  async login () {
    if (!this.authenticated) {
      await store.dispatch('resetState');
      // Vérifier si le paramètre kc_idp_hint est présent et récupérer sa valeur
      const idphint = this.retrieveIdpHintParameterFromUrl();
      return this.keycloak.login({
        redirectUri: window.location.href,
        idpHint: idphint
      });
    }
  }

  /**
   * Search the value for the parameter kc_idp_hint to make the redirection to Blocktel
   */

  retrieveIdpHintParameterFromUrl () {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get('kc_idp_hint');
    return (id);
  }

  /**
   * Log the user out of keycloak
   */
  logout () {
    if (this.interval) {
      clearInterval(this.interval);
    }
    return this.keycloak.logout({
      redirectUri: window.location.href
    });
  }

  isCreditor () {
    return this.hasRole('CREDITOR');
  }

  isCreditorAdmin () {
    return this.hasRole('CREDITOR_ADMIN');
  }

  isCreditorAccountManager () {
    return this.hasRole('CREDITOR_ACCOUNT_MANAGER');
  }

  isDebtor () {
    return this.hasRole('DEBTOR');
  }

  isDebtorExtSSOAutoLink () {
    return this.hasRole('DEBTOR_EXT_SSO_AUTO_ATTACH');
  }

  isBackofficeAdmin () {
    return this.hasRole('BACKOFFICE_ADMIN');
  }

  hasRole (role) {
    return this.user && this.user.groups && this.user.groups.includes(role);
  }
}

export default new KeycloakService();
