import environmentService from '@/services/environmentService';

export default {
  data () {
    return {
      clientName: '',
      clientITName: '',
      clientITAdresse: '',
      clientITNum: '',
      clientWebsite: '',
      clientServiceMail: '',
      clientsWithDefaultData: ['Indigo', 'Elto', 'Accor']
    };
  },
  async beforeMount () {
    this.clientWebsite = 'https://' + environmentService.realm + '.billpaymatch.com';
    if (environmentService.realm === 'indigo' || environmentService.realm === 'fr-indigo-gp' || environmentService.realm === 'fr-indigo-sigc') {
      this.clientName = 'Indigo';
      this.clientITName = 'Indigo Infra';
      this.clientITAdresse = 'Tour Voltaire, 1 Place des Degrés, 92800 PUTEAUX';
      this.clientITNum = '642 020 887';
      this.clientServiceMail = 'service.client@group-indigo.com';
    } else if (environmentService.realm === 'accor') {
      this.clientName = 'Accor';
      this.clientITName = 'Accor';
      this.clientITAdresse = 'Tour Voltaire, 1 Place des Degrés, 92800 PUTEAUX';
      this.clientITNum = '642 020 887';
      this.clientServiceMail = 'service.client@group-accor.com';
    } else if (environmentService.realm === 'demo') {
      this.clientName = 'Demo';
      this.clientITName = 'Demo';
      this.clientITAdresse = 'Tour Voltaire, 1 Place des Degrés, 92800 PUTEAUX';
      this.clientITNum = '+33 1 23 45 67 89';
      this.clientServiceMail = 'dl-fr-wbpm-dev-team@worldline.com';
    } else if (environmentService.realm === 'mps') {
      this.clientName = 'Elto';
      this.clientITName = 'ELTO HOLDING';
      this.clientITAdresse = '13 QUAI ALPHONSE LE GALLO 92100 BOULOGNE-BILLANCOURT';
      this.clientITNum = '881 926 307';
      this.clientServiceMail = 'contact@mobilize-ps.fr';
    } else if (environmentService.realm === 'iqera') {
      this.clientName = 'Iqera';
      this.clientITName = 'iQera';
      this.clientITAdresse = '256 BIS RUE DES PYRENEES CS 92042 75970 PARIS CEDEX 20';
      this.clientITNum = '+33252070309';
      this.clientServiceMail = 'dpo@iqera.com';
    } else if (environmentService.realm === 'bloctel') {
      this.clientName = 'Bloctel';
      this.clientITName = 'CONSOPROTEC';
      this.clientITAdresse = '19 RUE DE LA VALLEE MAILLARD 41000 BLOIS';
      this.clientITNum = '01 73 26 81 46';
      this.clientServiceMail = 'support.pro-bloctel@worldline.com';
    }
  }
};