<template>
  <div>
    <v-app-bar app color="white" class="ma-0 pa-0" elevation="0">
      <v-app-bar-nav-icon
        v-if="authenticated && (selectedDebtorAccount || isAdmin()) && isOnMobileSupport"
        @click.stop="drawer = !drawer"
        class="ml-0"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="pa-0">
        <router-link :to="homeLink">
          <v-img
            :src="logoUrl"
            :max-height="maxHeight"
            max-width="50vw"
            contain
            alt="Creditor logo"
          ></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer />

      <v-tabs center-active show-arrows class="ma-0" background-color ="white" color="white" v-if="isCreditor() && !isOnMobileSupport || isAdmin() && !isOnMobileSupport">
        <v-tabs-slider class="d-none"></v-tabs-slider>
        <v-tab
          class="rounded-t-xl"
          active-class="active-element"
          v-for="(item, i) in menuItems"
          :key="i"
          :to="{ name: item.linkTo }"
          :style="cssProps"
        >
          {{ item.menuLabel }}
        </v-tab>
      </v-tabs>

      <v-spacer />

      <CreditorAccountSelector
        v-if="showCreditorSelector"
      ></CreditorAccountSelector>

      <v-spacer />
      <v-btn
        v-if="isDebtor() && showDebtorSelector"
        class="ma-2 hidden-md-and-up"
        outlined
        color="primary"
        @click.stop="isDebtorSelectorEnabled = !isDebtorSelectorEnabled"
      >
        <span class="debtor-selector-button-content">{{
          selectedDebtorAccount.name
        }}</span>
        <v-spacer />
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>

      <v-menu v-if="authenticated && !isOnMobileSupport">
        <template v-if="!isOnMobileSupport" v-slot:activator="{ on, attrs }">
          <span class = "mx-2" v-bind="attrs" v-on="on" absolute>
            {{ user.fullName }}
            <v-icon color="black"> mdi-menu-down </v-icon>
          </span>
        </template>

        <template v-else v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" class="pr-3">
            <v-icon color="black"> mdi-account-circle </v-icon>
          </v-avatar>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                color="secondary"
                class="d-flex justify-center"
              >
                <span class="white--text headline">{{ userInitials }}</span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.fullName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item-group>
              <v-list-item
                v-for="item in userMenuItems"
                :key="item.linkTo"
                :to="{ name: item.linkTo }"
                v-model="selectedRoute"
                exact
              >
                <v-list-item-icon>
                  <v-icon :color="isLogoutMenu(item.menuLabel) ? undefined : 'secondary'">{{ item.iconLabel }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-on="isLogoutMenu(item.menuLabel) ? { click: logout } : {}">
                  <v-list-item-title>{{ item.menuLabel }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <span v-else-if="oneOffDebtor" class="mr-5 white--text">{{
        oneOffDebtor.name
      }}</span>
    </v-app-bar>
    <!-- PHONE DISPLAY -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      height="auto"
      class="main-navigation-drawer"
    >
      <v-list nav dense>

        <v-list-item
            v-for="item in menuItems.filter((item) => item.subMenu === undefined)"
            :key="item.linkTo"
            :to="{ name: item.linkTo }"
            v-model="selectedRoute"
            active-class="secondary--text text--accent-4"
        >
          <v-list-item-title>{{ item.menuLabel }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          :value="true"
          v-for="item in menuItems.filter((item) => item.subMenu !== undefined)"
          :key="item.linkTo"
          active-class="secondary--text text--accent-4"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.menuLabel }}</v-list-item-title>
          </template>
          <v-list-item
              v-for="(subItem, i) in item.subMenu"
              :key="i"
              :to="{ name: subItem.linkTo }"
              exact
          >
            <v-list-item-title>
              <v-badge
                  color="secondary"
                  :value="showMenuItemBadge(subItem.badgeProperty)"
                  offset-x="-5"
              >
                {{ subItem.menuLabel }}
                <span
                    slot="badge"
                    v-if="showMenuItemBadge(subItem.badgeProperty)"
                >
                {{ selectedDebtorAccount[subItem.badgeProperty] }}
              </span>
              </v-badge>
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-divider class="mt-3 mb-2"></v-divider>

        <v-list-group
            v-if = "!oneOffDebtor"
            :value="true"
            :key="user.fullName"
            active-class="secondary--text text--accent-4"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ user.fullName }}</v-list-item-title>
          </template>
          <v-list-item v-for="item in userMenuItems"
                       :key="item.linkTo"
                       :to="{ name: item.linkTo }"
                       v-model="selectedRoute"
                       exact
          >
            <v-list-item-content v-on="isLogoutMenu(item.menuLabel) ? { click: logout } : {}">
              <v-list-item-title>{{ item.menuLabel }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="showDebtorSelector"
      v-model="isDebtorSelectorEnabled"
      right
      absolute
      temporary
      class="debtor-selector-side"
    >
      <DebtorSelector></DebtorSelector>
    </v-navigation-drawer>
  </div>
</template>

<script>
import environmentService from '@/services/environmentService';
import keycloakService from '@/services/keycloakService';
import menuMixin from '@/mixins/menuMixin';
import userMixin from '@/mixins/userMixin';

import { mapState } from 'vuex';
import DebtorSelector from '@/components/debtor/DebtorSelector';
import CreditorAccountSelector from '../creditor/CreditorAccountSelector.vue';

export default {
  components: { DebtorSelector, CreditorAccountSelector },
  data: () => ({
    isDebtorSelectorEnabled: false,
    drawer: false,
    selectedRoute: null,
    logoUrl: environmentService.logoUrl,
    activeMenuBackgroundColor: null
  }),
  mixins: [userMixin, menuMixin],
  methods: {
    async logout () {
      await keycloakService.logout();
    },
    showMenuItemBadge (badgeProperty) {
      return (
        this.selectedDebtorAccount &&
        badgeProperty != null &&
        this.selectedDebtorAccount[badgeProperty]
      );
    },
    isLogoutMenu (menu) {
      return menu === this.$t('LOG_OUT');
    }
  },
  computed: {
    ...mapState([
      'selectedDebtorAccount',
      'debtorAccounts',
      'oneOffDebtor',
      'creditorAccounts',
      'primaryColor'
    ]),
    maxHeight () {
      if (['xs', 'sm'].includes(this.$vuetify.breakpoint.name)) {
        return 56;
      }
      return 64;
    },
    homeLink () {
      if (this.authenticated) {
        let link;
        if (this.isCreditor()) {
          link = 'CreditorHome';
        } else if (this.isDebtor()) {
          link = 'DebtorHome';
        } else if (this.isAdmin()) {
          link = 'BackofficeCustomerBilling';
        }
        return {
          name: link
        };
      }
      return {};
    },
    showDebtorSelector () {
      return this.debtorAccounts?.length > 1;
    },
    showCreditorSelector () {
      return (
        this.isCreditor() &&
        (this.creditorAccounts?.length > 1 ||
          this.creditorAccounts[0]?.children?.length >= 1)
      );
    },
    userMenuItems () {
      return [
        {
          linkTo: this.isCreditor() ? 'CreditorSettingsProfile' : 'DebtorSettingsProfile',
          menuLabel: this.isCreditor() ? this.$t('SETTINGS_MY_PROFILE') : this.$t('SETTINGS'),
          iconLabel: 'mdi-cog'
        },
        {
          linkTo: 'logout()',
          menuLabel: this.$t('LOG_OUT'),
          iconLabel: 'mdi-logout'
        }];
    },
    cssProps () {
      return {
        '--active-menu-background-color': this.activeMenuBackgroundColor
      };
    }
  },
  watch: {
    selectedRoute () {
      this.drawer = false;
    }
  },
  beforeMount () {
    this.activeMenuBackgroundColor = this.primaryColor;
  }
};
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding: 0;
}

.debtor-selector-button-content {
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  text-align: left !important;
}

.debtor-selector-side {
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
}

.main-navigation-drawer {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.spacer {
  width: 30px;
}

.active-element {
  background-color: var(--active-menu-background-color);
  border-color: var(--active-menu-background-color);
}

.v-tab:not(.v-tab--active) {
  background-color: #ffff;
}
</style>
