<template>
 <v-menu
      v-model="showmenu"
      :close-on-content-click = "false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
        >
        <span v-if="selectedCreditorAccount !== null">{{ selectedCreditorAccount.name }}</span>
        <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </template>
    <v-list class="pa-0" max-width=200px>
      <v-text-field
        prepend-icon="mdi-magnify"
        class="pa-2"
        v-model="search"
        hide-details
      ></v-text-field>
      <v-divider></v-divider>
      <v-treeview
        :active.sync="selectedCreditors"
        :items="creditorAccounts"
        :search="search"
        @update:active="getSelectedCreditor"
        item-key="accountId"
        activatable
        return-object
      >
        <template v-slot:label="{ item }">
          <v-list-item-content class="my-2 pa-0">
            <v-list-item-title v-html="item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="item.accountId"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-treeview>
    </v-list>
  </v-menu>
</template>

<script>

import { mapState } from 'vuex';
import userService from '@/services/userService';
import userMixin from '@/mixins/userMixin';

export default {
  data () {
    return {
      selectedCreditors: [],
      search: '',
      showmenu: false
    };
  },
  mixins: [userMixin],
  computed: {
    ...mapState(['creditorAccounts']),
    selectedCreditorAccount: {
      get: function () {
        return this.$store.state.selectedCreditorAccount;
      },
      set: function (newCreditorAccount) {
        this.$store.dispatch('setSelectedCreditorAccount', { creditorAccount: newCreditorAccount });
      }
    }
  },
  methods: {
    async getSelectedCreditor () {
      this.selectedCreditorAccount = this.selectedCreditors[0];
      if (this.isCreditorAccountManager()) {
        const { data } = await userService.getUserSubRoles(this.user.id, this.selectedCreditorAccount.id);
        const subRoles = data.map(roleDto => roleDto.role);
        await this.$store.dispatch('setSubRolesOnSelectedCreditorAccount', { subRolesOnSelectedCreditorAccount: subRoles });
      } else {
        await this.$store.dispatch('setSubRolesOnSelectedCreditorAccount', { subRolesOnSelectedCreditorAccount: [] });
      }
      const data = [];
      await this.$store.dispatch('setDebtorAccounts', data);
      await this.$store.dispatch('setSelectedDebtorAccountsForCreditor', data);
      await this.$store.dispatch('setSelectedDebtorAccount', {});
      this.showmenu = false;
    }
  }
};
</script>