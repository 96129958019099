<template>
  <div>
    <v-text-field
      hide-details
      prepend-icon="mdi-magnify"
      single-line
      class="ma-3"
      v-model="debtorAccountFilter"
    ></v-text-field>
    <v-divider></v-divider>
    <v-list>
      <v-list-item-group
        v-model="selectedDebtorAccount"
        color="primary">
        <template v-for="(debtorAccount, i) in filteredDebtorAccounts">
          <v-list-item
            :key="i"
            :value="debtorAccount"
            :disabled="isDebtorAccountSelected(debtorAccount.accountId)"
            :class="{
              'v-item--active v-list-item--active': isDebtorAccountSelected(debtorAccount.accountId)
            }"
            class="force-break-word py-1">
            <!-- badge désactivé pour avoir le bon curseur au survol -->
            <v-badge
                :disabled="isDebtorAccountSelected(debtorAccount.accountId)"
                color="secondary"
                offset-x="50"
                offset-y="25"
                :value="debtorAccount.toPayInvoicesCount || debtorAccount.toValidateInvoicesCount"
                :content="debtorAccount.toPayInvoicesCount + debtorAccount.toValidateInvoicesCount"
            >
              <v-list-item-content>
                <v-list-item-title v-html="debtorAccount.name"></v-list-item-title>
                <v-list-item-subtitle v-html="debtorAccount.accountId"></v-list-item-subtitle>
              </v-list-item-content>
            </v-badge>
          </v-list-item>
          <v-divider :key="debtorAccount.id"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <div class="text-center">
      <v-btn v-if="debtorAccountFilter" @click='cancelSearch' tile text>
        <v-icon>mdi-magnify-close</v-icon>
        {{ $t('ACTION_CANCEL') }}
      </v-btn>
      <router-link v-else
                   tag="button"
                   :aria-label="$t('ADD_DEBTOR')"
                   class="v-btn v-size--small primary my-3"
                   :to="{ name: 'DebtorSettingsAccounts', query: { addAccount: true } }">
        <v-icon color="white">mdi-plus</v-icon>
        <span class="ml-3 hidden-lg-and-down">{{ $t('ADD_DEBTOR') }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    debtorAccountFilter: ''
  }),
  computed: {
    ...mapState(['debtorAccounts']),
    selectedDebtorAccount: {
      get: function () {
        return this.$store.state.selectedDebtorAccount;
      },
      set: function (newDebtorAccount) {
        if (newDebtorAccount) {
          this.$store.dispatch('setSelectedDebtorAccount', { debtorAccount: newDebtorAccount });
        }
      }
    },
    filteredDebtorAccounts () {
      return this.debtorAccounts.slice().filter(
        debtorAccount => debtorAccount.name.toLowerCase().includes(this.debtorAccountFilter.toLowerCase())
      )
        // On tri sur le nombre de factures a valider ou à payer puis sur le nom du compte
        .sort((a, b) => (b.toPayInvoicesCount + b.toValidateInvoicesCount - (a.toPayInvoicesCount + a.toValidateInvoicesCount)) ||
        a.name.localeCompare(b.name));
    }
  },
  methods: {
    cancelSearch () {
      this.debtorAccountFilter = '';
    },
    isDebtorAccountSelected (debtorAccountId) {
      return this.selectedDebtorAccount && this.selectedDebtorAccount.accountId === debtorAccountId;
    }
  }
};
</script>

<style scoped>
  .v-list-item--active {
    font-weight: 500;
    border-left: 8px currentColor solid;
  }
</style>