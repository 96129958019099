import axios from 'axios';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import environmentService from '@/services/environmentService';
import keycloakService from '@/services/keycloakService';

/**
 * Service is an extendable class that allows calling
 * different API URLs
 */
export default class Service {
  constructor () {
    this.baseURL = environmentService.backendUrl;
  }

  /**
   * Retrieve axios instance with Authorization header (if user is authenticated)
   * @returns {axios} axios instance with Authorization header
   */
  get instance () {
    return axios.create({
      baseURL: this.baseURL,
      headers: omitBy({
        Authorization: `Bearer ${keycloakService.token}`
      }, isNull)
    });
  }

  /**
   * Retrieve axios instance with no Authorization header (even if user is authenticated)
   * @returns {axios} axios instance with Authorization header
   */
  get instanceNoAuth () {
    return axios.create({
      baseURL: this.baseURL
    });
  }
}
