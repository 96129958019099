import Vue from 'vue';
import VueRouter from 'vue-router';
import keycloakService from '@/services/keycloakService';
Vue.use(VueRouter);

const routes = [
  {
    path: '/creditor',
    component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/Home.vue'),
    children: [
      {
        path: 'configuration',
        name: 'CreditorConfiguration',
        component: { render: h => h('router-view') },
        redirect: {
          name: 'CreditorSettingsAccounts'
        },
        children: [
          {
            path: 'accounts',
            name: 'CreditorSettingsAccounts',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/settings/CreditorSettingsAccounts.vue')
          },
          {
            path: 'users',
            name: 'CreditorSettingsUsers',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/settings/CreditorSettingsUsers.vue'),
            beforeEnter: async (to, from, next) => {
              const user = keycloakService.user;
              if (user.groups.includes('CREDITOR_ADMIN')) {
                return next();
              }
              return next({ name: 'Error' });
            }
          }
        ]
      },
      {
        path: 'data_transfer',
        name: 'CreditorDataTransfer',
        component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorDataTransfer.vue'),
        meta: { hideMainNavigation: true }
      },
      {
        path: 'home',
        name: 'CreditorHome',
        component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorDashboard.vue'),
        meta: { hideMainNavigation: true, hideReturnButton: true }
      },
      {
        path: 'followUp',
        name: 'CreditorFollowUp',
        component: { render: h => h('router-view') },
        redirect: {
          name: 'CreditorPaidInvoices'
        },
        children: [
          {
            path: 'payment',
            name: 'CreditorPaymentInvoices',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorPaymentInvoices.vue')
          },
          {
            path: 'paid',
            name: 'CreditorPaidInvoices',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorPaidInvoices.vue')
          },
          {
            path: 'toPay',
            name: 'CreditorToPayInvoices',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorToPayInvoices.vue')
          },
          {
            path: 'rejected',
            name: 'CreditorRejectedInvoices',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorRejectedInvoices.vue')
          }
        ]
      },
      {
        path: 'debtManagement',
        name: 'DebtManagement',
        component: { render: h => h('router-view') },
        redirect: {
          name: 'CreditorCreatedInvoices'
        },
        children: [
          {
            path: 'created',
            name: 'CreditorCreatedInvoices',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorCreatedInvoices.vue')
          },
          {
            path: 'creation',
            name: 'CreditorCreateInvoices',
            component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/CreditorCreateInvoices.vue')
          }
        ]
      },
      {
        path: 'paymentDetail/:transactionReference',
        name: 'CreditorPaymentDetail',
        component: () => import(/* webpackChunkName: "creditor" */ '../views/payment/PaymentDetail.vue')
      },
      {
        path: 'invoice-details/:id',
        name: 'CreditorInvoiceDetail',
        component: () => import(/* webpackChunkName: "creditor" */ '../views/invoices/InvoiceDetail.vue')
      },

      {
        path: 'settings',
        name: 'CreditorSettings',
        component: () => import(/* webpackChunkName: "creditor" */ '../views/creditor/settings/CreditorSettings.vue'),
        meta: { hideMainNavigation: true },
        redirect: {
          name: 'CreditorSettingsProfile'
        },
        children: [
          {
            path: '',
            name: 'CreditorSettingsProfile',
            component: () => import(/* webpackChunkName: "creditor" */ '../components/common/settings/SettingsProfile.vue')
          }
        ]
      }
    ],
    meta: {
      group: 'CREDITOR'
    }
  },
  {
    path: '/debtor',
    component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/Home.vue'),
    children: [
      {
        path: 'home',
        name: 'DebtorHome',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/DebtorDashboard.vue')
      },
      {
        path: 'to-validate',
        name: 'DebtorToValidateInvoices',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/DebtorToValidateInvoices.vue')
      },
      {
        path: 'to-pay',
        name: 'DebtorToPayInvoices',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/DebtorToPayInvoices.vue')
      },
      {
        path: 'paid',
        name: 'DebtorPaidInvoices',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/DebtorPaidInvoices.vue')
      },
      {
        path: 'payments',
        name: 'DebtorPayments',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/DebtorPayments.vue')
      },
      {
        path: 'paymentDetail/:transactionReference',
        name: 'DebtorPaymentDetail',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/payment/PaymentDetail.vue')
      },
      {
        path: 'invoice-details/:id',
        name: 'DebtorInvoiceDetail',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/invoices/InvoiceDetail.vue')
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/settings/DebtorSettings.vue'),
        meta: { hideMainNavigation: true },
        children: [
          {
            path: '',
            name: 'DebtorSettingsProfile',
            component: () => import(/* webpackChunkName: "debtor" */ '../components/common/settings/SettingsProfile.vue')
          },
          {
            path: 'accounts',
            name: 'DebtorSettingsAccounts',
            component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/settings/DebtorSettingsAccounts.vue')
          },
          {
            path: 'account-details/:accountId/:action',
            name: 'DebtorSettingsAccountDetails',
            component: () => import(/* webpackChunkName: "debtor" */ '../views/debtor/settings/DebtorSettingsAccountDetails.vue')
          }
        ]
      },
      {
        path: 'checkout',
        name: 'DebtorCheckout',
        component: () => import(/* webpackChunkName: "debtor-checkout" */ '../views/debtor/DebtorCheckout.vue')
      },
      {
        path: 'transactions/:id',
        name: 'DebtorTransaction',
        component: () => import(/* webpackChunkName: "debtor-checkout" */ '../views/debtor/DebtorTransaction.vue')
      }
    ],
    meta: {
      group: 'DEBTOR'
    }
  },
  {
    path: '/backoffice',
    component: () => import(/* webpackChunkName: "admin" */ '../views/backoffice/Home.vue'),
    children: [
      {
        path: 'reporting',
        name: 'BackofficeReporting',
        component: { render: h => h('router-view') },
        redirect: {
          name: 'BackofficeCustomerBilling'
        },
        children: [
          {
            path: 'customer-billing',
            name: 'BackofficeCustomerBilling',
            component: () => import(/* webpackChunkName: "admin" */ '../views/backoffice/customer-reporting/BackofficeCustomerBilling.vue')
          },
          {
            path: 'providers-billing',
            name: 'BackofficeProvidersBilling',
            component: () => import(/* webpackChunkName: "admin" */ '../views/backoffice/customer-reporting/BackofficeProvidersBilling.vue')
          },
          {
            path: 'kpi-sales',
            name: 'BackofficeKpiSales',
            component: () => import(/* webpackChunkName: "admin" */ '../views/backoffice/customer-reporting/BackofficeKpiSales.vue')
          }
        ]
      },
      {
        path: 'management',
        name: 'BackofficeManagement',
        component: { render: h => h('router-view') },
        redirect: {
          name: 'BackofficeCreditorManagement'
        },
        children: [
          {
            path: 'file-exchanger',
            name: 'BackofficeFileExchanger',
            component: () => import(/* webpackChunkName: "admin" */ '../views/backoffice/customer-management/BackofficeFileExchanger.vue')
          },
          {
            path: 'creditor-management',
            name: 'BackofficeCreditorManagement',
            component: () => import(/* webpackChunkName: "admin" */ '../views/backoffice/customer-management/BackofficeCreditorManagement.vue')
          }
        ]
      }
    ],
    meta: {
      group: 'BACKOFFICE_ADMIN'
    }
  },
  {
    path: '/legal-terms',
    name: 'LegalTermsHome',
    component: () => import(/* webpackChunkName: "all" */ '../views/legal-terms/Home.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/one-off-payment',
    component: () => import(/* webpackChunkName: "one-off" */ '../views/one-off/Home.vue'),
    children: [
      {
        path: ':id',
        name: 'OneOffCheckout',
        component: () => import(/* webpackChunkName: "one-off" */ '../views/one-off/OneOffCheckout.vue')
      },
      {
        path: 'transactions/:id',
        name: 'OneOffTransaction',
        component: () => import(/* webpackChunkName: "one-off" */ '../views/one-off/OneOffTransaction.vue')
      }
    ],
    meta: {
      unprotected: true
    }
  },
  {
    path: '/faq',
    name: 'FaqHome',
    component: () => import(/* webpackChunkName: "all" */ '../views/faq/Home.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/personal-data',
    name: 'PersonalData',
    component: () => import(/* webpackChunkName: "all" */ '../views/personal-data/Home.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "all" */ '../views/about/Home.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "all" */ '../views/error/Home.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/',
    name: 'Default',
    redirect: {
      name: 'CreditorHome'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditionsHome',
    component: () => import(/* webpackChunkName: "all" */ '../views/terms-and-conditions/Home.vue'),
    meta: {
      unprotected: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  if (!to.matched.some(record => !!record.meta.unprotected) && !keycloakService.authenticated) {
    return keycloakService.login();
  } else {
    // if the route does not require a specific group, let go
    if (!to.matched.some(record => record.meta.group)) {
      return next();
    }
    const user = keycloakService.user;
    const group = to.matched.map(record => record.meta.group).filter(record => record)[0];
    // if the user group is already met, let go
    if (user.groups.includes(group)) {
      return next();
    }
    // if we are on the home page, let go
    if ((user.groups.includes('CREDITOR') && to.name === 'CreditorHome') ||
      (user.groups.includes('DEBTOR') && to.name === 'DebtorHome') ||
      (user.groups.includes('BACKOFFICE_ADMIN') && to.name === 'BackofficeCustomerBilling')) {
      return next();
    }

    let redirect;
    if (user.groups.includes('CREDITOR')) {
      redirect = 'CreditorHome';
    } else if (user.groups.includes('DEBTOR')) {
      redirect = 'DebtorHome';
    } else if (user.groups.includes('BACKOFFICE_ADMIN')) {
      redirect = 'BackofficeCustomerBilling';
    }
    return next({
      name: redirect
    });
  }
});

export default router;
