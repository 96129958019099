class CreditorUtils {
  /* cherche un crediteur dans la liste des crediteurs à ordonner */
  findCreditor (creditor, array) {
    let foundCreditor = null;
    let index = 0;
    let item = null;
    while ((foundCreditor === null) && (index < array.length)) {
      item = array[index++];
      if (item.accountId === creditor.accountId) {
        foundCreditor = item;
      } else if (item.children !== undefined) {
        foundCreditor = this.findCreditor(creditor, item.children);
      }
    };
    return foundCreditor;
  };

  /*
  Retourne la liste des parents liés aux enfants
   creditorsList:         orderedCreditorsList:
     c1                       c1
     c2                        c2
      c1        ==>             c3
     c3
      c2
       c1
  */
  orderList (creditorsList) {
    const orderedCreditorsList = [];
    let index = 0;
    let creditor = null;
    while (index < creditorsList.length) {
      creditor = creditorsList[index++];
      if (!creditor.visited) {
        if (!Object.prototype.hasOwnProperty.call(creditor, 'parent')) {
          orderedCreditorsList.push(creditor);
        } else {
          const parent = this.findCreditor(creditor.parent, creditorsList);
          if (parent != null) {
            parent.children = parent.children === undefined ? [] : parent.children;
            parent.children.push(creditor);
          } else {
            orderedCreditorsList.push(creditor);
          }
        }
        creditor.visited = true;
      }
    };
    return orderedCreditorsList;
  }
}
export default new CreditorUtils();