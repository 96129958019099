import Service from './Service';

class UserService extends Service {
  /**
   * Creates a user linked to a debtorAccount
   * @param {Object} user - user object sent by Vue app
   * @param {String} debtorId - debtor id
   *
   * @returns {Object} User created
   */
  createDebtorUser (user, debtorId) {
    return this.instance.post(`userapi/v1/debtoraccounts/${debtorId}/debtors`, user);
  }

  /**
   * Creates a user linked to a creditorAccount
   * @param {Object} creditorUser - user object sent by Vue app
   *
   * @returns {Object} User created
   */
  createCreditorUser (creditorUser) {
    return this.instance.post('userapi/public/v2/creditors/creditoraccounts', creditorUser);
  }

  /**
   * Returns the list of CreditorAccounts and their children on specific realm with sam information
   * @param {String} realm - realm of the connected user (ex: indigo)
   * @param {String} samLocale - language for sam session
   *
   * @returns {Object} List of CreditorAccounts
   */
  getRealmCreditorAccountsWithSamInformation (realm, samLocale) {
    return this.instance.get(`userapi/public/v2/creditors/${realm}/sam-information-accountsView`, {
      params: {
        frontLang: samLocale
      }
    });
  }

  /**
   * Returns the list of CreditorAccounts group on specific realm
   * @param {String} creditorId - id of the creditor user
   *
   * @returns {Object} List of CreditorAccounts group
   */
  getCreditorAccountsGroup (creditorId) {
    return this.instance.get('userapi/public/v2/creditors/creditoraccounts/group', {
      params: {
        userId: creditorId
      }
    });
  }

  /**
   * Update one accountName
   * @param {String} creditorId - id of the creditor user
   * @param {String} accountName - new name pour la compte
   *
   */
  updateAccount (creditorId, accountName) {
    return this.instance.put('userapi/public/v2/creditors/updateAccount', null, {
      params: {
        creditorId: creditorId,
        accountName: accountName
      }
    });
  }

  /**
   * Returns the list of CreditorAccounts on specific realm
   * @param {String} realm - realm of the selected customer
   */
  getRealmCreditorsAccounts (realm) {
    return this.instance.get(`userapi/public/v2/creditors/${realm}/list`);
  }

  /**
   * Returns the main creditor on specific realm
   * @param {String} realm - realm of the selected customer
   */
  getRealmMainCreditor (realm) {
    return this.instance.get('userapi/public/v2/creditors/main-creditor', {
      params: {
        realm: realm
      }
    });
  }

  /**
   * Returns all members of a given realm
   * @param {String} realm - realm of the connected user (ex: indigo)
   * @param {String} creditorId - id of the current selected creditor account
   *
   * @returns {Object} List of CreditorAccounts
   */
  getCreditorAccountUsers (realm) {
    return this.instance.get('userapi/public/v2/creditors/creditoraccounts/users', {
      params: {
        creditorAccountGroup: realm
      }
    });
  }

  /**
   * Updates a debtor user (debtorId is
   * @param {Object} user - Debtor user object sent by Vue app
   * @param {String} debtorId - Debtor id of the debtor account if the user is not the actual logged one
   *
   * @returns {Object} Debtor user updated
   */
  updateDebtorUser (user, debtorId = undefined) {
    if (debtorId === undefined) {
      return this.instance.put(`userapi/v1/debtors/${user.id}`, user);
    } else {
      return this.instance.put(`userapi/v1/debtors/${user.id}`, user, {
        params: {
          debtorId: debtorId
        }
      });
    }
  }

  /**
   * Updates a creditor user
   * @param {Object} user - Creditor user object sent by Vue app
   *
   * @returns {Object} Creditor user updated
   */
  updateCreditorUser (user) {
    return this.instance.put('userapi/public/v2/creditors/creditoraccounts', user);
  }

  /**
   * For a specified user, sends an email containing a link to reset his password
   * @param {String} userId - technical id of the user who wants to reset his password (ex: 1807bc72-a92d-4a7c-975a-ec507f63d52)
   *
   * @returns {Void} void
   */
  resetPassword (userId) {
    return this.instance.put(`userapi/v1/users/${userId}/sendEmailToUser`, ['VERIFY_EMAIL', 'UPDATE_PASSWORD']);
  }

  /**
   * Returns a debtorAccount
   * @param {String} debtorAccountId - debtor account id (ex: 987654321_123456789)
   *
   * @returns {Object} BillingAccountDto object
   */
  getDebtorAccount (debtorAccountId) {
    return this.instance.get(`userapi/v2/debtors/billingaccounts/${debtorAccountId}`);
  }

  /**
   * Returns a list of Users linked to a debtorAccount
   * @param {String} keycloakDebtorGroupId - keycloak debtor group code (ex: demo_123456789)
   *
   * @returns {Array} User objects array
   */
  getDebtorAccountMembers (debtorId) {
    return this.instance.get(`userapi/v1/debtoraccounts/${debtorId}/debtors`);
  }

  /**
   * Checks if an email matches a member of a debtorAccount
   * @param {String} email - email to control
   * @param {String} debtorId - debtor id
   *
   * @returns {Object} Object containing User if found, plus boolean attributes (unknownUser and userLinkedToDebtorAccount)
   */
  verifyUniqueLogin (email, debtorId) {
    return this.instance.get(`userapi/v1/users/${email}/debtorAccounts/${debtorId}`);
  }

  /**
   * Associates a debtor account to a debtor user
   * @param {String} debtorAccountId - the billing account id (ex: 987654321_123456789)
   * @param {String} secretKey - secretKey value of the business key to be used for this association
   *
   * @returns {Void} void
   */
  linkDebtorAccountToDebtorUser (debtorAccountId, secretKey) {
    return this.instance.put(`userapi/v1/debtors/${debtorAccountId}/business-keys/${secretKey}`);
  }

  /**
   * Associates a debtor account to a debtor user, for users having role DEBTOR_EXT_SSO_AUTO_ATTACH
   *
   * @returns {Void} void
   */
  autoLinkDebtorAccountToDebtorUser () {
    return this.instance.put('userapi/v1/debtors');
  }

  /**
   * Updates a debtor account
   * @param {Object} debtor - debtor to update
   */
  updateBillingAccount (debtor) {
    return this.instance.put(`userapi/v2/debtors/${debtor.id}`, debtor);
  }

  /**
   * Associates a user to a debtor account
   * @param {String} userId - id of the user to be associated to debtor account
   * @param {String} debtorId - debtor id
   * @param {String} role - role of the user for this debtor
   *
   * @returns {Void} void
   */
  linkUserToDebtorAccount (userId, debtorId, role) {
    return this.instance.post(`userapi/v1/users/${userId}/debtorAccounts/${debtorId}`, role,
      { headers: { 'Content-Type': 'text/plain' } });
  }

  /**
   * Updates a user role for a debtor account
   * @param {String} debtorAccountId - debtor id
   * @param {String} userId - id of the user to be updated
   * @param {String} role - role of the user for this debtor
   *
   * @returns {Void} void
   */
  assignRoleToUser (userId, debtorId, role) {
    return this.instance.put(`userapi/v1/debtoraccounts/${debtorId}/debtors/${userId}/roles`, role,
      { headers: { 'Content-Type': 'text/plain' } });
  }

  assignSubRolesToAccountManagerUser (roles, businessIds, userKeycloakId, businessType) {
    return this.instance.post('userapi/public/v2/user-role/', null, {
      params: {
        roles: roles.toString(),
        businessIds: businessIds.toString(),
        userKeycloakId: userKeycloakId,
        businessType: businessType
      }
    });
  }

  /**
   * dissociates a user to a debtor account
   * @param {String} userId - id of the user to be associated to debtor account
   * @param {String} keycloakDebtorGroupId - keycloak debtor group id (ex: demo_123456789)
   *
   * @returns {Void} void
   */
  dissociateUserFromDebtorAccount (userId, keycloakDebtorGroupId) {
    return this.instance.delete(`userapi/v1/users/${userId}/debtorAccounts/${keycloakDebtorGroupId}`);
  }

  /**
   * Disable creditor user
   * @param {String} creditorId - id of the creditor user to disable
   *
   * @returns {Void} void
   */
  disableCreditorUser (creditorId) {
    return this.instance.put(`userapi/v1/creditors/${creditorId}/disable`);
  }

  /**
   * Returns a creditorAccount
   * @param {String} creditorId - creditor id (ex: 223344556)
   *
   * @returns {Object} CreditorAccount object
   */
  getCreditorAccount (creditorId) {
    return this.instance.get(`userapi/v1/creditors/${creditorId}`);
  }

  /**
   * Returns a list of Creditors linked to a user
   *
   * @returns {Array} CreditorDto objects array
   */
  getCreditorAccountsOfUser () {
    return this.instance.get('userapi/public/v2/creditors');
  }

  /**
   * Returns a list of Debitors linked to a creditor
   *
   * @returns {Array} DebtorDto objects array
   */
  getDebtorsByCreditorId (creditorId) {
    return this.instance.get('userapi/public/v2/debtors', {
      params: {
        creditorId: creditorId
      }
    });
  }

  /**
   * Returns a list of Debitors linked to a creditor
   *
   * @returns {Array} DebtorDto objects array
   */
  getDebtorsByCreditorAndDebtorData (creditorId, data) {
    return this.instance.get('userapi/public/v2/debtors/filtered', {
      params: {
        creditorId: creditorId,
        data: data
      }
    });
  }

  /**
   * Returns a list of the user sub roles
   *
   * @returns {Array} UserRoleDto objects array
   */
  getUserSubRoles (userId, creditorId) {
    return this.instance.get('userapi/public/v2/user-role/sub-roles', {
      params: {
        userKeycloakId: userId,
        businessId: creditorId
      }
    });
  }
}

export default new UserService();
