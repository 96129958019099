import Logger from 'vuejs-logger';
import Vue from 'vue';

Vue.use(Logger, {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '/',
  showConsoleColors: true
});
