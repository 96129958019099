import Service from './Service';

class EventService extends Service {
  getServerInfo () {
    return this.instanceNoAuth.get('eventapi/v1/anonymous/info');
  }

  getDocumentEvents (invoiceId) {
    return this.instance.get('eventapi/v1/events', {
      params: {
        invoiceId: invoiceId
      }
    });
  }
}

export default new EventService();
